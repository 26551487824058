export default function(instance) {
	return {
		// ? REQUESTS (ЗАЯВКИ)
		// * Получение всех заявок
		load_request(params) {
			return instance({
				url: "/v2/dispatcher/request",
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		// * Создание новой заявки
		add_request(data) {
			return instance({
				url: "/bpmn/requests",
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		// * Детали определенной заявки
		load_request_detail(id) {
			return instance({
				url: `/v2/dispatcher/request/${id}`,
				method: "get",
			})
			.then(res => res.data)
			.catch(error => error);
		},

		// * Комментарии определенной заявки
		load_request_comments(params) {
			return instance({
				url: "/v2/comment/",
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Отправка коммента по определенной заявке
		send_request_comment(data) {
			return instance({
				url: "/v2/comment/announcement",
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Получение истории заявки
		load_request_history(id) {
			return instance({
				url: `/v1/news_feed/current/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Изменение деталей заявки
		change_request_status(id, status, data) {
			return instance({
				url: `/v1/dispatcher/request/${id}/change-type/${status}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		// * Изменение рейтинга заявки
		change_request_rating({ id, user_rating }) {
			return instance({
				url: `/v2/dispatcher/request/kskSide/${id}/rate`,
				method: "post",
				data: { user_rating },
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		// * Сохранение заявки
		save_request(data, id) {
			return instance({
				url: `/bpmn/requests/${id}/request-updated-event`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		// * Закрытие заявки (используется маршрут сотрудника)
		finish_request(data, id) {
			return instance({
				url: `/v2/dispatcher/employee/request/closing-work/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		// * Оценивание заявки
		rate_request({ id, data }) {
			return instance({
				url: `/bpmn/requests/${id}/request-confirmed-event`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

	};
}
